import React, { useState, useEffect } from "react";
import { Wrapper } from "./styles";
import Breadcrumb from "../../shared/Breadcrumbs";
import Loading from "../../shared/Loading";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { searchJira } from "../../httpRequests/jira";

function PMODashboard() {
  const [issues, setIssues] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    status: "",
    assignee: "",
  });
  const [tempFilter, setTempFilter] = useState(filter);

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage, filter]);

  const fetchData = async (page, rowsPerPage) => {
    setLoading(true);
    try {
      const response = await searchJira({
        jql: `project = IPP ${
          filter.status ? `AND status = "${filter.status}"` : ""
        } ${filter.assignee ? `AND assignee = "${filter.assignee}"` : ""}`,
        fields:
          "summary, status, assignee, customfield_10665, customfield_10666, customfield_10668, customfield_10669, customfield_10670, customfield_10671, customfield_10675, customfield_10678, customfield_10679",
        startAt: page * rowsPerPage,
        maxResults: rowsPerPage,
      });

      setIssues(response.data.issues);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data from Jira:", error);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTempFilterChange = (event) => {
    setTempFilter({ ...tempFilter, [event.target.name]: event.target.value });
  };

  const handleFilterSubmit = (event) => {
    event.preventDefault();
    setFilter(tempFilter);
    setPage(0); 
  };

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>PMO Dashboard</h2>
          <div className="flex">
            <Breadcrumb page="PMO Dashboard" link="#" />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <div className="dashboard-wrapper team-dashboard">
              <form
                onSubmit={handleFilterSubmit}
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
              >
                <FormControl variant="outlined" size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={tempFilter.status}
                    onChange={handleTempFilterChange}
                    label="Status"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="On Hold">On Hold</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  name="assignee"
                  value={tempFilter.assignee}
                  onChange={handleTempFilterChange}
                  label="Assignee"
                  variant="outlined"
                  size="small"
                />

                <Button type="submit" variant="contained" color="primary">
                  Apply Filters
                </Button>
              </form>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Issue Key</TableCell>
                      <TableCell>Summary</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Assignee</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {issues.map((issue) => (
                      <TableRow key={issue.id}>
                        <TableCell>{issue.key}</TableCell>
                        <TableCell>{issue.fields.summary}</TableCell>
                        <TableCell>{issue.fields.status.name}</TableCell>
                        <TableCell>
                          {issue.fields.assignee
                            ? issue.fields.assignee.displayName
                            : "Unassigned"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="pmo-pagination">
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </Box>
        )}
      </Container>
    </Wrapper>
  );
}

export default PMODashboard;
